import React, { FC, lazy, Suspense, useContext } from 'react';
import { Skeleton } from 'antd';
import { DeviceContext } from '../../../DeviceTypeContext';
import { IEvent } from '../../../events.model';
import './mobile.css';

type ComponentProps = {
  events: IEvent[];
  club: string;
};

const CalendarCompoent: FC<ComponentProps> = ({ events, club }) => {
  const { isDesktop } = useContext(DeviceContext);
  const Calendar = lazy(() => (isDesktop ? import('./Desktop') : import('./Mobile')));
  return (
    <Suspense fallback={<Skeleton active />}>
      <Calendar events={events} club={club} />
    </Suspense>
  );
};

export default CalendarCompoent;
