import { format, isPast, parseISO } from 'date-fns';

const DATE_TIME_TOKEN = 'MM/dd/yyyy h:mm aa';

function isInPast(datetime: string): boolean {
  const parsed = parseISO(datetime);
  return isPast(parsed);
}

function parseFormatDate(date: string, formatTokens: string): string {
  const parsed = parseISO(date);
  return format(parsed, formatTokens);
}

export { isInPast, parseFormatDate, DATE_TIME_TOKEN };

export function getUTCDay(day: any): number {
  const dayObj = typeof day === 'string' ? new Date(day) : day;
  return Math.ceil(dayObj.getTime() / (1000 * 60 * 60 * 24));
}
