import { RootDto } from './events.model';

function api<T>(url: string): Promise<T> {
  return fetch(url).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

const getEvents = (alias?: string | null) => {
  const URL = `/calendar?alias=${alias}`;
  return api<RootDto>(URL);
};

export { getEvents };
