import React, { FC, useContext, useState } from 'react';
import { Menu } from 'antd';
import { EventsContext } from '../EventsContext';

const Filter: FC<{}> = () => {
  const { getSchedules, setSelected, getSelectedSchedules } = useContext(EventsContext);

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const schedules = getSchedules();
  const selected = getSelectedSchedules();

  const [selectedKeys, setSelectedKeys] = useState<string[]>(selected.map(s => s.gymName));

  const select = (e: any) => {
    if (e.key === 'all') {
      setSelected(schedules);
      setSelectedKeys([...schedules.map(s => s.gymName), 'all']);
      return;
    }
    const gym = schedules.find(i => i.gymName === e.key);
    if (gym) {
      selected.push(gym);
      setSelected(selected);
      setSelectedKeys(selected.map(s => s.gymName));
      if (selected.length === schedules.length) {
        setSelectedKeys([...schedules.map(s => s.gymName), 'all']);
      }
    }
  };
  const deselect = (e: any) => {
    if (e.key === 'all') {
      setSelected([]);
      setSelectedKeys([]);
      return;
    }
    const index = selected.findIndex(i => i.gymName === e.key);
    selected.splice(index, 1);
    setSelectedKeys(selected.map(s => s.gymName));
    setSelected(selected);
  };
  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };
  const closeMenu = () => {
    setOpenKeys([]);
  };

  const getFilterValue = () => {
    if (selected.length && selected.length < schedules.length) {
      return selected.map(i => i.gymName).join(', ');
    }
    return 'All locations';
  };

  return (
    <div className="menuContainer">
      {openKeys.length ? (
        <div
          className="overlay"
          onClick={closeMenu}
          onTouchStart={closeMenu}
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 99
          }}
        ></div>
      ) : (
        ''
      )}
      {schedules.length ? (
        <Menu
          style={{
            width: '250px',
            maxHeight: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            margin: '0 40px',
            display: 'inline-block',
            zIndex: 100,
            top: '8px',
            position: 'absolute'
          }}
          multiple={true}
          mode="inline"
          onSelect={select}
          onDeselect={deselect}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <Menu.SubMenu
            key="sub1"
            title={
              <span>
                <span>{getFilterValue()}</span>
              </span>
            }
          >
            <Menu.Item key="all">All locations</Menu.Item>
            {schedules.map(schedule => (
              <Menu.Item key={schedule.gymName}>{schedule.gymName}</Menu.Item>
            ))}
          </Menu.SubMenu>
        </Menu>
      ) : (
        ''
      )}
    </div>
  );
};

export default Filter;
