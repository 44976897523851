import { useEffect, useState } from 'react';

const fakedMedia = () => ({
  matches: false,
  addListener: () => {},
  removeListener: () => {}
});

function useMedia(query: string): boolean {
  const matchMedia = window.matchMedia || fakedMedia;

  const [matches, setMatches] = useState(matchMedia(query).matches);

  useEffect(() => {
    const media = matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = (ev: MediaQueryListEvent) => setMatches(ev.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export default useMedia;
