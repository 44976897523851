import React, { FC, useContext, lazy, Suspense } from 'react';
import { Empty, Skeleton as Loader } from 'antd';
import Layout from '../../layout';
import { EventsContext } from '../../EventsContext';
import { DeviceContext } from '../../DeviceTypeContext';

const Entry: FC<{}> = () => {
  const { loading, getSchedules, getEventsByClub, getSelectedSchedules } = useContext(
    EventsContext
  );
  const { isDesktop } = useContext(DeviceContext);
  const schedules = getSchedules();
  const selected = getSelectedSchedules();
  const EventsList = lazy(() => (isDesktop ? import('./EntryList') : import('./EntryListMobile')));

  const content =
    schedules && schedules.length ? (
      <Suspense fallback={<Loader active />}>
        {(selected && selected.length ? selected : schedules).map((schedule, idx) => (
          <EventsList
            key={idx}
            events={getEventsByClub(schedule.gymName)}
            title={schedule.gymName}
          />
        ))}
      </Suspense>
    ) : (
      <Empty />
    );

  return <Layout wrapped={loading}>{(loading && <Loader active />) || content}</Layout>;
};

export default Entry;
