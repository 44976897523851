import React, { FC, PropsWithChildren, useContext } from 'react';
import { DeviceContext } from '../DeviceTypeContext';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

const Main: FC<PropsWithChildren<{ extra?: JSX.Element | null; wrapped?: boolean }>> = ({
  ...props
}) => {
  const { isDesktop } = useContext(DeviceContext);
  return isDesktop ? <Desktop {...props} /> : <Mobile {...props}>{props.children}</Mobile>;
};

export default Main;
