/** @jsxImportSource @emotion/react */
import { FC, useContext, useState } from 'react';
import { CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { Skeleton as Loader, Radio, Button } from 'antd';
import { Route, Routes } from 'react-router';
import Layout from '../../layout';
import EventsCalendar from './Calendar';
import EventsList from './List';
import { EventsContext } from '../../EventsContext';
import { DeviceContext } from '../../DeviceTypeContext';
import { css } from '@emotion/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

type RouteInfo = {
  club: string;
  eventName: string;
};

type ComponentState = {
  mode: ViewMode;
};

enum ViewModes {
  LIST = 'list',
  CALENDAR = 'calendar'
}

type ViewMode = ViewModes.CALENDAR | ViewModes.LIST;

const defaultViewMode = ViewModes.LIST;
const iconStyles = css`
  padding-right: 3px;
`;

const bottomMobileBtn = css`
  margin: 0;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  z-index: 1001;
  width: 50px;
  height: 50px;
  padding: 0;
`;

const Events: FC<{}> = () => {
  const [view, setView] = useState<ComponentState>({ mode: defaultViewMode });
  const { loading, getEventsByType } = useContext(EventsContext);
  const { isDesktop } = useContext(DeviceContext);
  const navigate = useNavigate();
  const params = useParams<RouteInfo>();
  const match = useLocation(); // TODO
  const back = () => {
    if (match.pathname) {
      navigate(`/${match.pathname.split('/')[1]}`);
      return;
    }
    navigate(-1);
  };

  const pageHeaderExtra = (
    <Radio.Group defaultValue={defaultViewMode} buttonStyle="solid">
      <Radio.Button value={ViewModes.LIST} onClick={() => setView({ mode: ViewModes.LIST })}>
        <UnorderedListOutlined css={iconStyles} />
        List View
      </Radio.Button>
      <Radio.Button
        value={ViewModes.CALENDAR}
        onClick={() => setView({ mode: ViewModes.CALENDAR })}
      >
        <CalendarOutlined css={iconStyles} />
        Calendar
      </Radio.Button>
    </Radio.Group>
  );

  const ViewModesSelector = loading ? null : isDesktop ? (
    <PageHeader onBack={back} title="Back" extra={pageHeaderExtra} />
  ) : (
    <Button
      type="primary"
      css={bottomMobileBtn}
      onClick={() =>
        setView({ mode: view.mode === ViewModes.CALENDAR ? ViewModes.LIST : ViewModes.CALENDAR })
      }
    >
      {view.mode === ViewModes.LIST ? <CalendarOutlined /> : <UnorderedListOutlined />}
    </Button>
  );

  const { eventName, club } = params;
  const events = getEventsByType(club!, eventName!);

  const EventsRouter = () => (
    <Routes>
      <Route
        path="/"
        element={
          view.mode === ViewModes.CALENDAR ? (
            <EventsCalendar events={events} club={club!} />
          ) : (
            <EventsList events={events} club={club!} />
          )
        }
      />
    </Routes>
  );

  return (
    <Layout extra={ViewModesSelector}>{loading ? <Loader active /> : <EventsRouter />}</Layout>
  );
};

export default Events;
