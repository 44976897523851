import React from 'react';
import { IEvent, RootDto, IEventList, GymSchedule } from './events.model';
import { generateICS, ICSParams } from './ics-generator';

interface EventsState {
  brand: string;
  schedules: GymSchedule[];
  selectedSchedules: GymSchedule[];
  error: null | Error;
  loading: boolean;
  setBrandShedules: (brand: RootDto) => void;
  setSelected: (schedules: GymSchedule[]) => void;
  setLoading: (state: boolean) => void;
  setError: (error: Error) => void;
  getEventsByType: (clubname: string, type: string) => IEvent[] | [];
  getEventsByClub: (clubname: string) => IEventList[];
  getSchedules: () => GymSchedule[];
  getSelectedSchedules: () => GymSchedule[];
  downloadICS: (params: ICSParams) => void;
  // getEventNamesByClub: (club: string) => string[] | [];
}

let EventsContext: React.Context<EventsState>;
const { Provider, Consumer } = (EventsContext = React.createContext<EventsState>(
  {} as EventsState
));

class EventsProvider extends React.Component<any, EventsState> {
  setBrandShedules = (schedules: RootDto): void => {
    this.setState({ loading: false, schedules: schedules.gymSchedules });
  };

  setSelected = (schedules: GymSchedule[]): void => {
    this.setState({ selectedSchedules: schedules });
  };

  setLoading = (state: boolean): void => {
    this.setState({ ...this.state, loading: state });
  };

  setError = (error: Error): void => {
    console.error('APP.ERROR', error);
    this.setState({ ...this.state, loading: false, error });
  };

  downloadICS = (params: ICSParams): void => {
    const link = document.createElement('a');
    const ICSContent = generateICS(params);
    link.setAttribute('href', `data:text/calendar;charset=utf8,${encodeURIComponent(ICSContent)}`);
    link.setAttribute('download', 'fitCalendar.ics');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // private getBrandClubs = () => {
  //   const { clubs } = this.state;
  //   return clubs.map((club: EventDto) => club.name);
  // };

  getEventsByType = (clubname: string, eventName: string): IEvent[] | [] => {
    const { schedules } = this.state;
    const clubSchedules = schedules.find(schedule => schedule.gymName === clubname);
    const scheduleByName =
      clubSchedules && clubSchedules.schedules.find(schedule => schedule.name === eventName);
    return (scheduleByName && scheduleByName.events) || [];
  };

  getSchedules = (): GymSchedule[] => {
    const { schedules } = this.state;
    return [...schedules];
  };

  getSelectedSchedules = (): GymSchedule[] => {
    const { selectedSchedules } = this.state;
    return [...selectedSchedules];
  };

  // getEventNamesByClub = (clubName: string): string[] => {} //TODO:

  getEventsByClub = (clubname: string): IEventList[] => {
    const { schedules } = this.state;
    const clubSchedules = schedules.find(schedule => schedule.gymName === clubname);
    return (
      (clubSchedules &&
        clubSchedules.schedules.map((schedule, idx) => {
          return {
            id: idx,
            name: schedule.name,
            brandName: clubname,
            external: schedule.url,
            eventsCount: schedule.events ? schedule.events.length : null
          };
        })) ||
      []
    );
  };

  public state: EventsState = {
    brand: '',
    schedules: [],
    selectedSchedules: [],
    error: null,
    loading: true,
    setBrandShedules: this.setBrandShedules,
    setLoading: this.setLoading,
    setError: this.setError,
    getEventsByType: this.getEventsByType,
    getEventsByClub: this.getEventsByClub,
    getSchedules: this.getSchedules,
    getSelectedSchedules: this.getSelectedSchedules,
    setSelected: this.setSelected,
    downloadICS: this.downloadICS
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export { EventsProvider, Consumer as TracksConsumer, EventsContext };
