import React, { FC, useEffect, useContext } from 'react';
import { Entry, Events } from './pages';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import { EventsContext } from './EventsContext';
import { getEvents } from './api';
import NotFound from './404';

type RouteParams = {
  brand: string;
};

const App: FC<{}> = () => {
  const location = useLocation();
  const params = useParams<RouteParams>();
  const { setLoading, error, setBrandShedules, setError } = useContext(EventsContext);
  const { brand } = params;

  useEffect(() => {
    setLoading(true);
    getEvents(brand)
      .then(setBrandShedules)
      .catch(setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const router = (
    <Routes>
      <Route path="/" element={<Entry />} />
      <Route path="/events/:club/:eventName/*" element={<Events />} />
    </Routes>
  );

  return error ? <NotFound /> : router;
};

export default App;
