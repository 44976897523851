import React, { FC, lazy, Suspense, useContext } from 'react';
import { Skeleton } from 'antd';
import { DeviceContext } from '../../../DeviceTypeContext';
import { IEvent } from '../../../events.model';

type ComponentProps = {
  events: IEvent[];
  club: string;
};

const ListCompoent: FC<ComponentProps> = ({ events, club }) => {
  const { isDesktop } = useContext(DeviceContext);
  const List = lazy(() => (isDesktop ? import('./List') : import('./ListMobile')));
  return (
    <Suspense fallback={<Skeleton active />}>
      <List events={events} club={club} />
    </Suspense>
  );
};

export default ListCompoent;
