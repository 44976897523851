import React, { createContext } from 'react';
import useMedia from './useMedia';

interface Context {
  isDesktop: boolean;
}

const DeviceContext: React.Context<Context> = createContext({} as Context);

const DeviceContextProvider: React.FC<{ children: JSX.Element | string }> = ({ children }) => (
  <DeviceContext.Provider value={{ isDesktop: useMedia('screen and (min-width: 1025px)') }}>
    {children}
  </DeviceContext.Provider>
);

export { DeviceContextProvider as DeviceProvider, DeviceContext };
