import React, { FC, Fragment } from 'react';
import { AppstoreFilled } from '@ant-design/icons';
import { Layout } from 'antd';
import { Grid } from 'antd-mobile';
import { Filter } from '../components';
import { useLocation } from 'react-router-dom';

const { Header } = Layout;

const MobileView: FC<{
  extra?: JSX.Element | null;
  children: React.ReactNode;
}> = ({ extra, children }) => {
  const location = useLocation();
  let isListview = location.pathname.split('/').length === 2;
  return (
    <Fragment>
      <Header>
        <AppstoreFilled style={{ color: '#fff', fontSize: '22px' }} />
        {isListview && <Filter />}
      </Header>
      {extra}
      <Grid columns={1}>
        <Grid.Item>{children}</Grid.Item>
      </Grid>
    </Fragment>
  );
};

const Mobile = MobileView;

export { Mobile };
