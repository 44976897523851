import React, { FC, PropsWithChildren } from 'react';
import { AppstoreFilled } from '@ant-design/icons';
import { Layout } from 'antd';
import { InnerContent as Inner } from '../components';
import { Filter } from '../components';
import { useLocation } from 'react-router-dom';

const { Header, Content } = Layout;

const DesktopView: FC<PropsWithChildren<{ extra?: JSX.Element | null; wrapped?: boolean }>> = ({
  children,
  extra,
  wrapped
}) => {
  const location = useLocation();
  let isListview = location.pathname.split('/').length === 2;
  return (
    <Content style={{ minHeight: '100vh' }}>
      <Header>
        <AppstoreFilled style={{ color: '#fff', fontSize: '22px' }} />
        {isListview && <Filter />}
      </Header>
      <Content style={{ margin: '10px', backgroundColor: '#fff' }}>
        {extra}
        {wrapped ? <Inner>{children}</Inner> : children}
      </Content>
    </Content>
  );
};

const Desktop = DesktopView;

export { Desktop };
