import { v5 as uuidV5 } from 'uuid';
import { parseFormatDate } from './common';
const FORMAT_TOKENS = `yyyyMMdd'T'HHmmss`;

export type ICSParams = {
  location?: string;
  start: string;
  end: string;
  summary: string;
  description?: string;
};

function truncate(str: string): string {
  const maxCount = 72;
  const tail = '...';
  return str
    .replace(/\r?\n|\r/g, '') //remove line breaks
    .substring(0, maxCount)
    .concat(tail);
}

function generateICS(params: ICSParams) {
  const { summary, location = '', start, end, description = '' } = params;
  return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:Fit Calendar
BEGIN:VEVENT
UID:${uuidV5(window.location.href, uuidV5.URL)}
DTSTAMP:${parseFormatDate(new Date().toISOString(), FORMAT_TOKENS)}
DTSTART:${parseFormatDate(start, FORMAT_TOKENS)}
DTEND:${parseFormatDate(end, FORMAT_TOKENS)}
SUMMARY:${summary}
LOCATION:${location}
DESCRIPTION:${truncate(description)}
END:VEVENT
END:VCALENDAR`;
}

export { generateICS };
