import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'normalize.css';
import './index.css';
import NotFound from './404';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { EventsProvider } from './EventsContext';
import { DeviceProvider } from './DeviceTypeContext';

const Root = () => (
  <EventsProvider>
    <DeviceProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/:brand/*" element={<App />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </DeviceProvider>
  </EventsProvider>
);

const root = createRoot(document.getElementById('app-mount')!);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
