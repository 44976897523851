/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';

const styled = css`
  padding: 20px;
`;

const Inner: React.FC<PropsWithChildren<{}>> = ({ children }) => <div css={styled}>{children}</div>;

export default Inner;
